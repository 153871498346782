var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fadeIn"}},[(_vm.showCtaNewsletter)?_c('div',{ref:"modalNewsletter",staticClass:"modal-newsletter",class:{'full-height': _vm.continueToSubscribe}},[_c('div',{staticClass:"backdrop",on:{"click":function($event){$event.preventDefault();return _vm.hideModal($event)}}}),_c('div',{staticClass:"modal-newsletter__content"},[_c('button',{staticClass:"btn btn-icon close-modal",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.hideModal($event)}}},[_c('i',{staticClass:"aqz-icon aqz-icon-04-close"})]),_c('div',{staticClass:"modal-newsletter__content__body"},[_c('div',{staticClass:"wrapp-main-image"},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.newsletterImage),expression:"newsletterImage",arg:"background-image"}],staticClass:"background-full"})]),_c('div',{staticClass:"wrapp-info"},[_c('no-ssr',[_c('overlay-scrollbars',{ref:"newsletterScrollbar",staticClass:"scrollable-content",attrs:{"options":{ paddingAbsolute: true, scrollbars: { autoHide: 'scroll' }, overflowBehavior: {x: 'hidden', y: 'scroll'} }}},[_c('h3',{staticClass:"title f-size"},[_vm._v(_vm._s(_vm.$t('newsletterModal.title')))]),_c('div',{staticClass:"wrapp-form-newsletter"},[_c('div',{staticClass:"form-intro"},[_c('p',{staticClass:"t-small"},[_vm._v(_vm._s(_vm.$t('newsletterModal.intro')))])]),_c('b-form',{ref:"scrollAnchor",staticClass:"form-newsletter",attrs:{"data-vv-scope":"form-cta-newsletter"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('b-form-group',{attrs:{"id":"email","label-for":"email"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],staticClass:"form-border-line",attrs:{"name":"email","type":"email","placeholder":("*" + (_vm.$t('email'))),"validations":[
                        {
                          condition: _vm.errors.has('form-cta-newsletter.email'),
                          text: _vm.errors.first('form-cta-newsletter.email')
                        }
                      ]},model:{value:(_vm.newsletter.email),callback:function ($$v) {_vm.$set(_vm.newsletter, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newsletter.email"}}),_c('div',{staticClass:"valid-feedback"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('newsletter_success'))+" ")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.continueToSubscribe),expression:"continueToSubscribe"}],staticClass:"newsletter-hidden-content"},[_c('b-form-group',[_c('base-textarea',{attrs:{"readonly":true,"name":"privacy-explanation","value":_vm.$t('newsletterModal.privacy-explanation')}})],1),_c('p',{staticClass:"text-line line-title"},[_vm._v("\n                      "+_vm._s(_vm.$t('newsletterModal.content-title'))+"\n                    ")]),_c('p',{staticClass:"text-line"},[_vm._v("\n                      "+_vm._s(_vm.$t('newsletterModal.privacies-step-intro'))+"\n                    ")]),_c('div',{staticClass:"privacy-acceptance-block"},_vm._l((_vm.newsletter.privacy),function(value,key){return _c('div',{key:key,staticClass:"privacy-terms"},[_c('b-form-group',{attrs:{"id":'checkbox_'+key,"label-for":key}},[_c('base-check-box',{directives:[{name:"validate",rawName:"v-validate",value:((_vm.privacyMandatory[key] ? 'required:true' : '')),expression:"(privacyMandatory[key] ? 'required:true' : '')"}],key:key,attrs:{"name":key,"label":_vm.labelCheckbox[key],"use_prevent":false,"validations":[
                              {
                                condition: _vm.errors.has(("form-cta-newsletter." + key)),
                                text: _vm.errors.first(("form-cta-newsletter." + key))
                              }
                            ]},model:{value:(_vm.newsletter.privacy[key]),callback:function ($$v) {_vm.$set(_vm.newsletter.privacy, key, $$v)},expression:"newsletter.privacy[key]"}})],1)],1)}),0)],1),_c('re-CAPTCHA',{ref:"nlReCaptcha",attrs:{"isReCaptchaVerified":_vm.isReCaptchaVerified,"isReCaptchaInvalid":_vm.isReCaptchaInvalid,"reCaptchaToken":_vm.reCaptchaToken},on:{"update:isReCaptchaVerified":function($event){_vm.isReCaptchaVerified=$event},"update:is-re-captcha-verified":function($event){_vm.isReCaptchaVerified=$event},"update:isReCaptchaInvalid":function($event){_vm.isReCaptchaInvalid=$event},"update:is-re-captcha-invalid":function($event){_vm.isReCaptchaInvalid=$event},"update:reCaptchaToken":function($event){_vm.reCaptchaToken=$event},"update:re-captcha-token":function($event){_vm.reCaptchaToken=$event}}}),_c('div',{staticClass:"form-actions"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.continueToSubscribe),expression:"!continueToSubscribe"}],staticClass:"btn btn-secondary full-width",attrs:{"type":"button"},on:{"click":_vm.showContent}},[_vm._v(_vm._s(_vm.$t('newsletterModal.continue-subscribe')))]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.continueToSubscribe),expression:"continueToSubscribe"}],staticClass:"btn btn-primary full-width",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('subscribe')))])])],1)],1)])],1)],1)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }