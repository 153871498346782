<template>
  <div class="home">
    <!-- home block -->
    <!-- <static-home></static-home> -->
    <div v-append-html="pageContent"/>
    <modal-newsletter></modal-newsletter>
    <modal-country v-model="showModal" @close="closeCountry"></modal-country>
  </div>
</template>

<script>
// @ is an alias to /src
import ModalNewsletter from '@/components/Modal/ModalNewsletter'
/* import StaticHome from '@/theme/BackOffice/StaticHome/Home' */
import CmsContent from '@/components/CmsContent'
import ModalCountry from '@/theme/components/Modal/Country.vue'
import config from '@/config'
import { EventBus } from '@/helpers'

export default {
  name: 'home',
  components: {
    CmsContent,
    /* StaticHome, */
    ModalNewsletter,
    ModalCountry
  },
  head () {
    const title = this.$store.state.cms.meta.title
    const description = this.$store.state.cms.meta.description
    const keywords = this.$store.state.cms.meta.keywords
    return {
      title: title,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: description
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content: keywords
      },
      {
        vmid: 'pinterest-site-verification',
        name: 'p:domain_verify',
        content: config.API.pinterest.verificationCode
      }]
    }
  },
  methods: {
    getCountryByIp () {
      if (global.$cookies.get('country-choice') !== null) {
        if (global.$cookies.get('country-choice') !== this.$store.state.ui.storeViewCode) {
          window.location.href = '/' + global.$cookies.get('country-choice')
        } else {
          return true
        }
      } else {
        return fetch('https://ipinfo.io/json?token=' + process.env.VUE_APP_IPINFO_TOKEN).then(response => response.json()).then(data => {
          this.showModal = data.country !== this.$store.state.ui.currentCountry.country
        })
      }
    },
    closeCountry ($event) {
      this.showModal = $event
    }
  },
  data () {
    return {
      showModal: false
    }
  },
  mounted () {
    if (process.client) {
      EventBus.$once('countries:loaded', this.getCountryByIp)
      // go to forgot passord
      if (this.$route.query.forgotpassword && this.$route.query.forgotpassword === 'true') {
        let componentToLoad = import('@/theme/components/RightNavigation/Auth/ForgotPassword')
        this.$store.dispatch('ui/loadActiveComponent', { component: componentToLoad })
      }
      // go to login
      if (this.$route.query.login && this.$route.query.login === 'true') {
        let componentToLoad = import('@/theme/components/RightNavigation/Auth/Login')
        this.$store.dispatch('ui/loadActiveComponent', { component: componentToLoad })
      }
    }
  },
  computed: {
    pageContent () {
      return this.$store.getters['cms/getContentPage']
    }
  },
  asyncData ({ store, redirect }) {
    return store.dispatch('cms/getPage', store.getters['storeConfig/homePage'])
      .then(() => {
        // dati strutturati
        let jsonLD = []
        let metaHrefLang = [{
          rel: 'alternate',
          hreflang: 'x-default',
          href: process.env.VUE_APP_BASE_PATH + '/'
        }]
        if (store.state.storeConfig.config.seo_markup_seller &&
            store.state.storeConfig.config.seo_markup_seller.show === 'only_home_page' &&
            store.state.storeConfig.config.seo_markup_seller.json) {
          jsonLD.push(store.state.storeConfig.config.seo_markup_seller.json)
        }
        // speakable default
        if (store.state.storeConfig.config.seo_markup_speakable &&
            store.state.storeConfig.config.seo_markup_speakable.json_page) {
          jsonLD.push(store.state.storeConfig.config.seo_markup_speakable.json_page)
        }
        // href lang
        if (store.state.cms.meta.href_lang_links) {
          metaHrefLang = store.state.cms.meta.href_lang_links.map(item => {
            let itemParsed = JSON.parse(item)
            return {
              rel: 'alternate',
              hreflang: itemParsed.hreflang,
              href: itemParsed.href.replace(/\/(\w|[-])+\/$/, '')
            }
          })
        }
        store.commit('ui/setJsonLD', jsonLD)
        store.commit('ui/setHrefLang', metaHrefLang)
        store.commit('ui/setCanonical', `${process.env.VUE_APP_BASE_PATH}/${store.state.ui.storeViewCode}/`)
      })
      .catch(() => {
        redirect({ name: 'page-not-found', params: { lang: store.state.ui.storeViewCode } })
      })
  }
}
</script>
