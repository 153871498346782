<template>
  <transition name="fadeIn">
    <div class="modal-newsletter" v-if="showCtaNewsletter" ref="modalNewsletter" :class="{'full-height': continueToSubscribe}">
      <div class="backdrop" @click.prevent="hideModal"></div>
      <div class="modal-newsletter__content">
        <button type="button" class="btn btn-icon close-modal" @click.prevent="hideModal">
          <i class="aqz-icon aqz-icon-04-close"></i>
        </button>
        <div class="modal-newsletter__content__body">
          <div class="wrapp-main-image">
            <div class="background-full" v-lazy:background-image="newsletterImage"></div>
          </div>
          <div class="wrapp-info">
            <no-ssr>
              <overlay-scrollbars class="scrollable-content" :options="{ paddingAbsolute: true, scrollbars: { autoHide: 'scroll' }, overflowBehavior: {x: 'hidden', y: 'scroll'} }" ref="newsletterScrollbar">
                <h3 class="title f-size">{{ $t('newsletterModal.title') }}</h3>
                <div class="wrapp-form-newsletter">
                  <div class="form-intro">
                    <p class="t-small">{{ $t('newsletterModal.intro') }}</p>
                  </div>
                  <b-form class="form-newsletter" @submit.prevent="submitForm" data-vv-scope="form-cta-newsletter" ref="scrollAnchor">
                    <b-form-group id="email" label-for="email">
                      <base-input
                        name="email"
                        class="form-border-line"
                        v-model.trim="newsletter.email"
                        v-validate="'required|email'"
                        type="email"
                        :placeholder="`*${$t('email')}`"
                        :validations="[
                          {
                            condition: errors.has('form-cta-newsletter.email'),
                            text: errors.first('form-cta-newsletter.email')
                          }
                        ]"
                      >
                      </base-input>
                      <div class="valid-feedback"><div> {{$t('newsletter_success')}} </div></div>
                    </b-form-group>
                    <div class="newsletter-hidden-content" v-show="continueToSubscribe">
                      <b-form-group>
                        <base-textarea
                          :readonly="true"
                          name="privacy-explanation"
                          :value="$t('newsletterModal.privacy-explanation')"
                        ></base-textarea>
                      </b-form-group>
                      <p class="text-line line-title">
                        {{$t('newsletterModal.content-title')}}
                      </p>
                      <p class="text-line">
                        {{$t('newsletterModal.privacies-step-intro')}}
                      </p>
                      <div class="privacy-acceptance-block">
                        <div v-for="(value, key) in newsletter.privacy" :key="key" class="privacy-terms">
                          <b-form-group :id="'checkbox_'+key" :label-for="key">
                            <base-check-box
                              :name="key"
                              :key="key"
                              v-validate="(privacyMandatory[key] ? 'required:true' : '')"
                              v-model="newsletter.privacy[key]"
                              :label="labelCheckbox[key]"
                              :use_prevent="false"
                              :validations="[
                                {
                                  condition: errors.has(`form-cta-newsletter.${key}`),
                                  text: errors.first(`form-cta-newsletter.${key}`)
                                }
                              ]"
                            >
                            </base-check-box>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                    <re-CAPTCHA
                      ref="nlReCaptcha"
                      :isReCaptchaVerified.sync="isReCaptchaVerified"
                      :isReCaptchaInvalid.sync="isReCaptchaInvalid"
                      :reCaptchaToken.sync="reCaptchaToken">
                      <!-- <template v-slot:button>
                        <button>Click me</button>
                      </template> -->
                    </re-CAPTCHA>
                    <div class="form-actions">
                      <button type="button" class="btn btn-secondary full-width" v-show="!continueToSubscribe" @click="showContent">{{ $t('newsletterModal.continue-subscribe') }}</button>
                      <button type="submit" class="btn btn-primary full-width" v-show="continueToSubscribe">{{ $t('subscribe') }}</button>
                    </div>
                  </b-form>
                </div>
              </overlay-scrollbars>
            </no-ssr>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import $ from 'jquery'
import BaseInput from '@/components/Form/BaseInput'
import BaseCheckBox from '@/components/Form/BaseCheckBox'
import BaseTextarea from '@/components/Form/BaseTextarea'
import Newsletter from '@/services/Newsletter'
// import { toast } from '@/modules/notifications'
import { EventBus } from '@/helpers'
import { pathTranslate } from '@/helpers/routeHelpers'
import Logger from '@/services/Logger'
import reCAPTCHA from '@/theme/components/reCAPTCHA/reCAPTCHA.vue'

export default {
  inject: ['$validator'],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      newsletter: {
        email: '',
        privacy: {
          marketingTerms: false,
          // terms2: false,
          profilingTerms: false
        }
      },
      privacyMandatory: { 'marketingTerms': true },
      continueToSubscribe: false,
      showCtaNewsletter: null,
      isReCaptchaVerified: false,
      isReCaptchaInvalid: false,
      reCaptchaToken: undefined
    }
  },
  mounted () {
    if (process.client) {
      let newsletterCookieVal = !!global.$cookies.get('hide-cta-newsletter')
      this.showCtaNewsletter = !newsletterCookieVal
      $('html').toggleClass('no-overflow', !newsletterCookieVal)
    }
  },
  computed: {
    labelCheckbox () {
      return {
        'marketingTerms': this.labelLinkPrivacy,
        // 'terms1': this.$t('newsletter.terms1'),
        // 'terms2': this.$t('newsletter.terms2'),
        'profilingTerms': this.$t('newsletterModal.termsProfiling')
      }
    },
    labelLinkPrivacy () {
      let linkPrivacy = this.$router.resolve({ name: 'genericTerms', params: { slug: pathTranslate({ path: 'privacy-policy' }), lang: this.$store.state.ui.storeViewCode } }).href
      return this.$t('newsletterModal.termsMarketing', { linkPrivacy })
    },
    newsletterImage () {
      let newsletterMedias = this.$store.getters['storeConfig/modalNewsletterMedia']
      if (this.$store.state.ui.isMobile) {
        return newsletterMedias.mobileMedia
      } else {
        return newsletterMedias.desktopMedia
      }
    }
  },
  methods: {
    showContent () {
      this.continueToSubscribe = true
      if (this.$refs.newsletterScrollbar) {
        const osInstance = this.$refs.newsletterScrollbar.osInstance()
        osInstance.scrollStop().scroll('100%')
      }
    },
    clearForm () {
      this.newsletter.email = ''
      this.newsletter.privacy.marketingTerms = false
      this.newsletter.privacy.profilingTerms = false
      this.$validator.reset()
    },
    submitForm ($event) {
      const _this = this
      this.$validator.validateAll('form-cta-newsletter')
        .then(isValid => {
          if (isValid) {
            if (_this.reCaptchaToken) {
              return Newsletter.subscribeCustomer(
                _this.$store.state.ui.storeViewCode,
                _this.newsletter.email,
                _this.reCaptchaToken,
                this.newsletter.privacy.marketingTerms,
                this.newsletter.privacy.profilingTerms
              )
                .then((res) => {
                  if (res.data !== 'OK') {
                    throw new Error('error')
                  }
                  EventBus.$emit('track:newsletter', { email: _this.newsletter.email, $event })
                  _this.$refs.nlReCaptcha.$refs.vueReCaptcha.reset()
                  _this.reCaptchaToken = ''
                  $('#email .valid-feedback').css('display', 'block')
                  setTimeout(function () {
                    _this.hideModal()
                  }, 1500)
                  // toast.success(_this.$t('newsletter_success'))
                  _this.clearForm()
                })
                .catch((err) => {
                  Logger.error(err)
                  $('#email input').addClass('is-invalid')
                  $('#email .invalid-feedback').html(_this.$t('newsletter_server_error'))
                  setTimeout(function () {
                    $('#email .invalid-feedback').html('')
                  }, 2500)
                })
            } else {
              _this.isReCaptchaInvalid = true
            }
          }
        })
    },
    hideModal () {
      this.showCtaNewsletter = false
      this.closeNewsletterBlock()
    },
    closeNewsletterBlock () {
      $('html').removeClass('no-overflow')
      global.$cookies.set('hide-cta-newsletter', true)
    }
  },
  components: {
    BaseInput,
    BaseCheckBox,
    BaseTextarea,
    reCAPTCHA
  }
}
</script>
